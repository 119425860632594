// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-choosing-the-right-business-process-management-solution-js": () => import("./../src/pages/blog/choosing-the-right-business-process-management-solution.js" /* webpackChunkName: "component---src-pages-blog-choosing-the-right-business-process-management-solution-js" */),
  "component---src-pages-blog-improving-customer-experience-with-an-advisory-board-js": () => import("./../src/pages/blog/improving-customer-experience-with-an-advisory-board.js" /* webpackChunkName: "component---src-pages-blog-improving-customer-experience-with-an-advisory-board-js" */),
  "component---src-pages-blog-wasteful-processes-slow-down-software-delivery-efficiency-and-effectiveness-js": () => import("./../src/pages/blog/wasteful-processes-slow-down-software-delivery-efficiency-and-effectiveness.js" /* webpackChunkName: "component---src-pages-blog-wasteful-processes-slow-down-software-delivery-efficiency-and-effectiveness-js" */),
  "component---src-pages-business-consulting-js": () => import("./../src/pages/business-consulting.js" /* webpackChunkName: "component---src-pages-business-consulting-js" */),
  "component---src-pages-business-consulting-business-analysis-and-value-targeting-js": () => import("./../src/pages/business-consulting/business-analysis-and-value-targeting.js" /* webpackChunkName: "component---src-pages-business-consulting-business-analysis-and-value-targeting-js" */),
  "component---src-pages-business-consulting-business-architecture-js": () => import("./../src/pages/business-consulting/business-architecture.js" /* webpackChunkName: "component---src-pages-business-consulting-business-architecture-js" */),
  "component---src-pages-business-consulting-business-process-improvement-js": () => import("./../src/pages/business-consulting/business-process-improvement.js" /* webpackChunkName: "component---src-pages-business-consulting-business-process-improvement-js" */),
  "component---src-pages-business-consulting-business-strategy-js": () => import("./../src/pages/business-consulting/business-strategy.js" /* webpackChunkName: "component---src-pages-business-consulting-business-strategy-js" */),
  "component---src-pages-business-consulting-customer-experience-and-journey-mapping-js": () => import("./../src/pages/business-consulting/customer-experience-and-journey-mapping.js" /* webpackChunkName: "component---src-pages-business-consulting-customer-experience-and-journey-mapping-js" */),
  "component---src-pages-business-consulting-enterprise-program-management-js": () => import("./../src/pages/business-consulting/enterprise-program-management.js" /* webpackChunkName: "component---src-pages-business-consulting-enterprise-program-management-js" */),
  "component---src-pages-business-consulting-operational-and-process-excellence-js": () => import("./../src/pages/business-consulting/operational-and-process-excellence.js" /* webpackChunkName: "component---src-pages-business-consulting-operational-and-process-excellence-js" */),
  "component---src-pages-business-consulting-people-and-change-consulting-js": () => import("./../src/pages/business-consulting/people-and-change-consulting.js" /* webpackChunkName: "component---src-pages-business-consulting-people-and-change-consulting-js" */),
  "component---src-pages-business-consulting-performance-measurement-js": () => import("./../src/pages/business-consulting/performance-measurement.js" /* webpackChunkName: "component---src-pages-business-consulting-performance-measurement-js" */),
  "component---src-pages-business-consulting-process-and-capability-assessment-js": () => import("./../src/pages/business-consulting/process-and-capability-assessment.js" /* webpackChunkName: "component---src-pages-business-consulting-process-and-capability-assessment-js" */),
  "component---src-pages-business-consulting-vision-and-strategy-development-js": () => import("./../src/pages/business-consulting/vision-and-strategy-development.js" /* webpackChunkName: "component---src-pages-business-consulting-vision-and-strategy-development-js" */),
  "component---src-pages-careers-js": () => import("./../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-digital-consulting-services-js": () => import("./../src/pages/digital-consulting-services.js" /* webpackChunkName: "component---src-pages-digital-consulting-services-js" */),
  "component---src-pages-digital-consulting-services-customer-experience-and-design-js": () => import("./../src/pages/digital-consulting-services/customer-experience-and-design.js" /* webpackChunkName: "component---src-pages-digital-consulting-services-customer-experience-and-design-js" */),
  "component---src-pages-digital-consulting-services-digital-strategy-js": () => import("./../src/pages/digital-consulting-services/digital-strategy.js" /* webpackChunkName: "component---src-pages-digital-consulting-services-digital-strategy-js" */),
  "component---src-pages-digital-consulting-services-digital-technology-services-js": () => import("./../src/pages/digital-consulting-services/digital-technology-services.js" /* webpackChunkName: "component---src-pages-digital-consulting-services-digital-technology-services-js" */),
  "component---src-pages-digital-consulting-services-test-automation-and-devops-success-js": () => import("./../src/pages/digital-consulting-services/test-automation-and-devops-success.js" /* webpackChunkName: "component---src-pages-digital-consulting-services-test-automation-and-devops-success-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industry-expertise-js": () => import("./../src/pages/industry-expertise.js" /* webpackChunkName: "component---src-pages-industry-expertise-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-technology-services-js": () => import("./../src/pages/technology-services.js" /* webpackChunkName: "component---src-pages-technology-services-js" */),
  "component---src-pages-technology-services-cloud-computing-js": () => import("./../src/pages/technology-services/cloud-computing.js" /* webpackChunkName: "component---src-pages-technology-services-cloud-computing-js" */),
  "component---src-pages-technology-services-data-and-analytics-js": () => import("./../src/pages/technology-services/data-and-analytics.js" /* webpackChunkName: "component---src-pages-technology-services-data-and-analytics-js" */),
  "component---src-pages-technology-services-devops-js": () => import("./../src/pages/technology-services/devops.js" /* webpackChunkName: "component---src-pages-technology-services-devops-js" */),
  "component---src-pages-technology-services-enterprise-application-and-solutions-js": () => import("./../src/pages/technology-services/enterprise-application-and-solutions.js" /* webpackChunkName: "component---src-pages-technology-services-enterprise-application-and-solutions-js" */),
  "component---src-pages-technology-services-enterprise-collaboration-js": () => import("./../src/pages/technology-services/enterprise-collaboration.js" /* webpackChunkName: "component---src-pages-technology-services-enterprise-collaboration-js" */),
  "component---src-pages-technology-services-it-strategy-js": () => import("./../src/pages/technology-services/it-strategy.js" /* webpackChunkName: "component---src-pages-technology-services-it-strategy-js" */),
  "component---src-pages-technology-services-mobile-app-development-js": () => import("./../src/pages/technology-services/mobile-app-development.js" /* webpackChunkName: "component---src-pages-technology-services-mobile-app-development-js" */),
  "component---src-pages-technology-services-modern-software-delivery-js": () => import("./../src/pages/technology-services/modern-software-delivery.js" /* webpackChunkName: "component---src-pages-technology-services-modern-software-delivery-js" */),
  "component---src-pages-technology-services-outsourcing-js": () => import("./../src/pages/technology-services/outsourcing.js" /* webpackChunkName: "component---src-pages-technology-services-outsourcing-js" */)
}

